// webpack entry points are allowed to be side effectful
/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import "./css/font.css";
import "./lib/styles/blueprint_overrides.css";
import { FocusStyleManager } from "@blueprintjs/core";
import { enableMapSet } from "immer";
import moment from "moment-timezone";
import momentTimezoneData from "moment-timezone/data/packed/latest.json";
import React from "react";
import { render } from "react-dom";

import App from "./app";
import { buildLaunchDarklyProvider, getLdClient } from "./global-constants.js";
import { APPLIED_AO_LOG } from "./util/AppliedAOLog.js";
import { CONNECTION_LOG } from "./util/ConnectionLog";
import {
  analyticsEnabled,
  rudderstackDataPlaneUrl,
  rudderstackWriteKey,
  webClientBuildTime,
  webClientVersion,
} from "./util/data";
import { initDatadogLogging } from "./util/initDatadog.js";
import { logErrorMsg } from "./util/logging";
import { RUDDER_ANALYTICS } from "./util/rudderstack.js";
import { TRACE_LOG } from "./util/TraceLog";

enableMapSet();

// eslint-disable-next-line no-console
console.info(`Starting Hex web client
version:    ${webClientVersion}
build time: ${webClientBuildTime}
Use debugInfo() to print more info
`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__TRACE_LOG__ = TRACE_LOG;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__CONNECTION_LOG__ = CONNECTION_LOG;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__APPLIED_AO_LOG__ = APPLIED_AO_LOG;

if (analyticsEnabled) {
  try {
    initDatadogLogging();
  } catch (e) {
    console.error("Error initializing Datadog", e);
  }
}

if (rudderstackWriteKey != null && rudderstackDataPlaneUrl != null) {
  try {
    RUDDER_ANALYTICS.load(rudderstackWriteKey, rudderstackDataPlaneUrl, {
      // Grab the anonymousId from Segment's cookie if it's available and set them in rudderstack for
      // migration purposes. Even though we no longer use Segment, this code is still neccessary
      // since Segment may have previously given the user an anonymousId that we want to be able to
      // track user behavior from before we switched to Rudderstack.
      anonymousIdOptions: {
        // @ts-expect-error -- Rudderstack type defns are out of date, confirmed this is correct
        localStorage: true,
        cookie: {
          name: "ajs_anonymous_id",
          domain: "hex.tech",
          path: "/",
        },
      },
    });
  } catch (e) {
    logErrorMsg(e, "Failed to initialize rudderstack");
  }
}

FocusStyleManager.onlyShowFocusOnTabs();
moment.tz.load(momentTimezoneData);

void (async () => {
  const ldClient = getLdClient();
  let toRender = <App />;

  if (ldClient) {
    const LDProvider = await buildLaunchDarklyProvider(ldClient);
    toRender = <LDProvider>{toRender}</LDProvider>;
  }

  render(toRender, document.getElementById("root"));
})();
