import {
  CellId,
  MagicEventFailureReason,
  MagicEventStatus,
  UPDATE_MAGIC_EVENT,
} from "@hex/common";
import { useCallback } from "react";

import { useLatestMagicEventForCellSelector } from "../hex-version-multiplayer/state-hooks/magicEventStateHooks.js";
import { MagicEventMP } from "../redux/slices/hexVersionMPSlice.js";
import { MagicExploreValidationResult } from "../redux/slices/logicViewSlice.js";
import { useHexVersionAOContext } from "../util/hexVersionAOContext.js";

/** Reactively update explore join validation results. */
export const useUpdateExploreValidation = (cellId: CellId) => {
  const { dispatchAO } = useHexVersionAOContext();
  const latestMagicEventId = useLatestMagicEventForCellSelector({
    cellId,
    selector: (magicEventState: MagicEventMP | undefined) =>
      magicEventState?.id,
    safe: true,
  });

  return useCallback(
    (data: MagicExploreValidationResult) => {
      if (latestMagicEventId == null) return;

      if (data === "TIMEOUT") {
        dispatchAO([
          UPDATE_MAGIC_EVENT.create(
            latestMagicEventId,
            "failureReason",
            MagicEventFailureReason.JOIN_VALIDATION_TIMEOUT,
          ),
        ]);
      } else if (data === "ERROR") {
        dispatchAO([
          UPDATE_MAGIC_EVENT.create(
            latestMagicEventId,
            "failureReason",
            MagicEventFailureReason.INVALID_JOIN,
          ),
          UPDATE_MAGIC_EVENT.create(
            latestMagicEventId,
            "status",
            MagicEventStatus.ERROR,
          ),
        ]);
      }
    },
    [latestMagicEventId, dispatchAO],
  );
};
