import { rgba } from "polished";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css, useTheme } from "styled-components";

import { HexButton } from "../hex-components";
import { rotate } from "../theme/common/animations";

export type MagicElementState = "default" | "active" | "loading";

export const MAGIC_ELEMENT_SIZES: Record<string, number> = {
  Default: 20,
  StillThinking: 94,
};

const StillThinkingAnimationContainer = styled.div`
  padding-right: 5px;
  overflow: hidden;

  white-space: nowrap;

  opacity: 0;

  transition: opacity ${({ theme }) => theme.animation.durationMedium}
    ${({ theme }) => theme.animation.easing};

  &.still-thinking-enter {
    opacity: 0;
  }
  &.still-thinking-enter-active,
  &.still-thinking-enter-done,
  &.still-thinking-exit {
    opacity: 1;
  }
  &.still-thinking-exit-active,
  &.still-thinking-exit-done {
    opacity: 0;
  }
`;

const Wrapper = styled.div<{
  $state: MagicElementState;
  $stillThinking: boolean;
  $noBackground: boolean;
}>`
  display: flex;
  flex: none;
  align-items: center;
  width: ${({ $stillThinking }) =>
    $stillThinking
      ? `${MAGIC_ELEMENT_SIZES.StillThinking}px`
      : `${MAGIC_ELEMENT_SIZES.Default}px`};
  height: ${MAGIC_ELEMENT_SIZES.Default}px;
  overflow: hidden;

  color: ${({ theme }) => theme.magic.primaryColor};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: default;

  transition:
    background-color ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    width ${({ theme }) => theme.animation.durationMedium}
      ${({ theme }) => theme.animation.easing};

  &:hover {
    background: ${({ $noBackground, theme }) =>
      $noBackground ? "none" : rgba(theme.magic.primaryColor, 0.1)};
  }

  ${({ $noBackground, $state, $stillThinking }) =>
    ($state === "active" ||
      $state === "loading" ||
      $stillThinking ||
      $noBackground) &&
    css`
      background: ${({ theme }) =>
        $noBackground ? "none" : rgba(theme.magic.primaryColor, 0.1)};
    `}
`;

const VerticalCircles = styled.div`
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 18px;
  margin-left: -9px;

  transition:
    top ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    bottom ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};
`;

const HorizontalCircles = styled.div`
  position: absolute;
  top: 50%;

  right: 3px;
  left: 3px;

  display: flex;
  align-items: center;

  height: 18px;
  margin-top: -9px;

  transition:
    left ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    right ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    opacity ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};
`;

const Circle = styled.div`
  position: absolute;
  flex: none;
  width: 18px;
  height: 18px;

  border-radius: 50%;

  box-shadow: inset 0 0 0 1.2px ${({ theme }) => theme.magic.primaryColor};

  transition:
    box-shadow ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    width ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    height ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};
`;

const TopCircle = styled(Circle)`
  top: 0;
`;

const BottomCircle = styled(Circle)`
  bottom: 0;
`;

const LeftCircle = styled(Circle)`
  left: 0;
`;

const RightCircle = styled(Circle)`
  right: 0;
`;

const Icon = styled.div<{
  $state: MagicElementState;
  $stillThinking: boolean;
}>`
  position: relative;

  display: flex;
  flex: none;
  width: ${MAGIC_ELEMENT_SIZES.Default}px;
  height: ${MAGIC_ELEMENT_SIZES.Default}px;
  scale: 75%;

  ${({ $state }) =>
    $state === "default" &&
    css`
      ${HorizontalCircles} {
        opacity: 0;
        right: 3px;
        left: 3px;
        transform: rotate(0deg);
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }
      ${VerticalCircles} {
        opacity: 1;
        transform: rotate(0deg);
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }
    `}

  ${({ $state }) =>
    $state === "active" &&
    css`
      ${HorizontalCircles} {
        opacity: 1;
        right: 4px;
        left: 4px;
        transform: rotate(90deg);
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }

      ${VerticalCircles} {
        opacity: 0.6;
        top: 4px;
        bottom: 4px;
        transform: rotate(90deg);
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }

      ${LeftCircle},
      ${RightCircle} {
        box-shadow: inset 0 0 0 1.2px ${({ theme }) => theme.magic.primaryColor};
      }
    `}

  ${({ $state }) =>
    $state === "loading" &&
    css`
      ${VerticalCircles} {
        opacity: 1;
        top: 4px;
        bottom: 4px;
        animation: ${rotate} 1250ms ease-in-out infinite;
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }

      ${HorizontalCircles} {
        opacity: 0.6;
        right: 4px;
        left: 4px;
        animation: ${rotate} 2500ms ease-in-out infinite;
        transition: ${({ theme }) => theme.animation.durationMedium}
          ${({ theme }) => theme.animation.easing};
      }
    `}
`;

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StopButton = styled.div`
  color: ${({ theme }) => theme.magic.primaryColor};
`;

export interface MagicElementProps {
  className?: string;
  state?: MagicElementState;
  stillThinking?: boolean;
  noBackground?: boolean;
  onClick?: () => void;
}

export const MagicElement: React.ComponentType<MagicElementProps> = React.memo(
  function MagicElement({
    className,
    noBackground = false,
    onClick,
    state = "default",
    stillThinking = false,
  }) {
    const theme = useTheme();
    const stillThinkingRef = useRef<HTMLDivElement>(null);

    return (
      <OuterWrapper className={className}>
        {stillThinking && (
          <HexButton
            css={`
              margin-right: 5px;
            `}
            small={true}
            subtle={true}
            onClick={onClick}
          >
            <StopButton>Stop</StopButton>
          </HexButton>
        )}
        <Wrapper
          $noBackground={noBackground}
          $state={state}
          $stillThinking={stillThinking}
          onClick={onClick}
        >
          <Icon $state={state} $stillThinking={stillThinking}>
            <VerticalCircles>
              <TopCircle />
              <BottomCircle />
            </VerticalCircles>
            <HorizontalCircles>
              <LeftCircle />
              <RightCircle />
            </HorizontalCircles>
          </Icon>
          <CSSTransition
            appear={true}
            classNames="still-thinking"
            in={stillThinking}
            mountOnEnter={true}
            nodeRef={stillThinkingRef}
            timeout={theme.animation.durationMediumMs}
            unmountOnExit={true}
          >
            <StillThinkingAnimationContainer ref={stillThinkingRef}>
              Thinking...
            </StillThinkingAnimationContainer>
          </CSSTransition>
        </Wrapper>
      </OuterWrapper>
    );
  },
);
