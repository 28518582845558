import { gql, useApolloClient } from "@apollo/client";
import { Classes, Intent, Placement } from "@blueprintjs/core";
import { DOCS_LINKS, OrgRole } from "@hex/common";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { DocsLink } from "../components/common/DocsLink";
import { Heading } from "../components/Heading";
import { HexButton, HexPopover, HexSwitch } from "../hex-components";
import { useUserForMagic } from "../hooks/magicHooks.js";
import { useCurrentUser } from "../hooks/me/useCurrentUser";
import { useCurrentUserIsAdmin } from "../hooks/me/useCurrentUserIsAdmin.js";
import { useUpdateOrgMagicSettings } from "../hooks/useUpdateOrgMagicSettings";
import { useUpdateUserSettings } from "../hooks/useUpdateUserSettings.js";
import { CyData } from "../util/cypress.js";

import { useUpdateHexMagicMutation } from "./MagicOnboardingPopover.generated";

const Wrapper = styled.div`
  max-width: 320px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  color: ${({ theme }) => theme.fontColor.MUTED};

  p {
    strong {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

gql`
  mutation UpdateHexMagic($hexMagic: Boolean!) {
    updateHexMagic(enabled: $hexMagic) {
      id
      hexMagic
    }
  }
`;

export const MagicOnboardingWrapper: React.ComponentType<{
  onAccept?: () => void;
  onClose?: () => void;
}> = React.memo(function MagicOnboardingWrapper({ onAccept, onClose }) {
  const currentUser = useCurrentUser();
  const [updateHexMagic] = useUpdateHexMagicMutation();
  const apolloClient = useApolloClient();

  const setMagicOnboarded = useCallback(async () => {
    if (currentUser?.id) {
      await updateHexMagic({ variables: { hexMagic: true } });
      onAccept?.();
      await apolloClient.refetchQueries({ include: ["GetUserForMagic"] });
    }
    onClose?.();
  }, [apolloClient, currentUser, onAccept, onClose, updateHexMagic]);

  return (
    <Wrapper>
      <Heading renderAs="h1" styleAs="h3">
        Hex Magic
      </Heading>
      <p>
        By accepting, you acknowledge that you agree to the{" "}
        <DocsLink to={DOCS_LINKS.MagicTerms}>Terms and Conditions</DocsLink>.
      </p>
      <HexButton
        className={Classes.POPOVER_DISMISS}
        data-cy={CyData.MAGIC_ACCEPT_POPOVER}
        intent={Intent.PRIMARY}
        onClick={setMagicOnboarded}
      >
        Accept
      </HexButton>
    </Wrapper>
  );
});

/**
 * This is a new TOS for Magic that includes the updates needed to turn on
 * Typeahead. We should deprecate the old TOS and other wrapper once
 * we've officially turned on typeahead.
 */
export const MagicOnboardingWrapperNewTos: React.ComponentType<{
  onAccept?: () => void;
  onClose?: () => void;
  userPreviouslyAcceptedTos: boolean;
  hideTypeaheadToggle?: boolean;
}> = React.memo(function MagicOnboardingWrapperNewTos({
  hideTypeaheadToggle,
  onAccept,
  onClose,
  userPreviouslyAcceptedTos,
}) {
  const currentUser = useCurrentUser();
  const { updateMagicTypeaheadAndTos } = useUpdateUserSettings();
  const apolloClient = useApolloClient();

  const { updateOrgMagicTosAcceptedJuly2024, updateOrgMagicTypeaheadEnabled } =
    useUpdateOrgMagicSettings();
  const [isTypeaheadEnabled, setIsTypeaheadEnabled] = useState(true);

  const setMagicOnboarded = useCallback(async () => {
    if (currentUser?.id) {
      await updateMagicTypeaheadAndTos(true, isTypeaheadEnabled);
    }
    // allow admins to accept TOS for all users and
    // enable typeahead for the org.
    if (currentUser?.orgRole === OrgRole.ADMIN) {
      await updateOrgMagicTosAcceptedJuly2024(true);
      await updateOrgMagicTypeaheadEnabled(isTypeaheadEnabled);
    }
    onAccept?.();
    await apolloClient.refetchQueries({ include: ["GetUserForMagic"] });
    onClose?.();
  }, [
    apolloClient,
    currentUser?.id,
    currentUser?.orgRole,
    isTypeaheadEnabled,
    onAccept,
    onClose,
    updateMagicTypeaheadAndTos,
    updateOrgMagicTosAcceptedJuly2024,
    updateOrgMagicTypeaheadEnabled,
  ]);

  // TODO: handle the actual edit callbacks here
  const handleTypeaheadToggle = useCallback(() => {
    setIsTypeaheadEnabled((prev) => !prev);
  }, []);

  const tosLanguage = userPreviouslyAcceptedTos ? (
    <>
      <p>
        We&apos;ve updated our Terms and Conditions. By accepting, you agree to
        the updated{" "}
        <DocsLink to={DOCS_LINKS.MagicTermsTypeahead}>
          Terms and Conditions
        </DocsLink>
        , including the use of{" "}
        <DocsLink to={DOCS_LINKS.MagicTypeahead}> Magic Typeahead</DocsLink>.
        You can disable Magic Typeahead below.
      </p>
    </>
  ) : (
    <p>
      By accepting, you acknowledge that you agree to the{" "}
      <DocsLink to={DOCS_LINKS.MagicTermsTypeahead}>
        Terms and Conditions
      </DocsLink>
      , including the use of{" "}
      <DocsLink to={DOCS_LINKS.MagicTypeahead}> Magic Typeahead</DocsLink>.
    </p>
  );

  return (
    <Wrapper>
      <Heading renderAs="h1" styleAs="h3">
        Hex Magic
      </Heading>
      {tosLanguage}
      {!hideTypeaheadToggle && (
        <SwitchWrapper>
          <span>Magic Typeahead</span>
          <HexSwitch
            alignIndicator="right"
            checked={isTypeaheadEnabled}
            label={isTypeaheadEnabled ? "On" : "Off"}
            onChange={handleTypeaheadToggle}
          />
        </SwitchWrapper>
      )}
      <ButtonWrapper>
        <HexButton
          className={Classes.POPOVER_DISMISS}
          data-cy={CyData.MAGIC_ACCEPT_POPOVER}
          intent={Intent.PRIMARY}
          onClick={setMagicOnboarded}
        >
          Accept
        </HexButton>
      </ButtonWrapper>
    </Wrapper>
  );
});

export interface MagicOnboardingPopoverProps {
  children?: React.ReactNode;
  className?: string;
  placement?: Placement;
  fill?: boolean; // whether the popover should fill space or be inline
  isOpen?: boolean; // for controlled use only
  onClose?: () => void; // for controlled use only
  onAccept?: () => void;
  hideTypeaheadToggle?: boolean; // if the enable typeahead toggle should be hidden
}

export const MagicOnboardingPopover: React.ComponentType<MagicOnboardingPopoverProps> =
  React.memo(function MagicOnboardingPopover({
    children,
    className,
    fill,
    hideTypeaheadToggle,
    isOpen,
    onAccept,
    onClose,
    placement,
  }: MagicOnboardingPopoverProps) {
    const {
      magicTypeaheadAllowed,
      onboardedToMagic,
      onboardedToMagicTypeahead,
    } = useUserForMagic();
    const isAdmin = useCurrentUserIsAdmin();

    if (magicTypeaheadAllowed || isAdmin) {
      return (
        <HexPopover
          autoFocus={true}
          canEscapeKeyClose={true}
          className={className}
          content={
            <MagicOnboardingWrapperNewTos
              hideTypeaheadToggle={hideTypeaheadToggle}
              userPreviouslyAcceptedTos={onboardedToMagic}
              onAccept={onAccept}
              onClose={onClose}
            />
          }
          css={fill ? "flex: 1" : "display: inline"}
          disabled={onboardedToMagicTypeahead}
          enforceFocus={true}
          fill={true}
          isOpen={isOpen}
          minimal={true}
          placement={placement}
          usePortal={true}
          onClose={onClose}
        >
          {children}
        </HexPopover>
      );
    } else {
      return (
        <HexPopover
          autoFocus={true}
          canEscapeKeyClose={true}
          className={className}
          content={
            <MagicOnboardingWrapper onAccept={onAccept} onClose={onClose} />
          }
          css={fill ? "flex: 1" : "display: inline"}
          disabled={onboardedToMagic}
          enforceFocus={true}
          fill={true}
          isOpen={isOpen}
          minimal={true}
          placement={placement}
          usePortal={true}
          onClose={onClose}
        >
          {children}
        </HexPopover>
      );
    }
  });
