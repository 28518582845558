/* eslint-disable max-lines-per-function */
import { ApolloError, gql, useApolloClient } from "@apollo/client";
import {
  CellId,
  DataSourceTableId,
  MagicAcceptType,
  MagicCellEditTypeLiteral,
  MagicEventId,
  MagicEventStatus,
  MagicKeyword,
  MagicKeywordLiteral,
  MentionedDataframeName,
  RichTextDocument,
  SmartEditType,
  UPDATE_MAGIC_EVENT,
  convertRichTextToPlainText,
  notEmpty,
  uuid,
} from "@hex/common";
import { editor as Editor, Range, Selection } from "monaco-editor";
import { useCallback, useRef, useState } from "react";
import { Subscription } from "zen-observable-ts";

import { getImperativeModel } from "../components/cell/monaco/useImperativeModel";
import { MagicEventFragment } from "../hex-version-multiplayer/HexVersionMPModel.generated";
import { useCellContentsGetter } from "../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import {
  useLatestMagicEventForCellGetter,
  useMagicEventsGetter,
} from "../hex-version-multiplayer/state-hooks/magicEventStateHooks.js";
import { useInsertCell } from "../hooks/cell/insert-move/index.js";
import { duplicateCellContentsPayload } from "../hooks/cell/useCopyCell";
import { useFormatCells } from "../hooks/cell/useFormatCell.js";
import { useRunCell } from "../hooks/cell/useRunCell.js";
import { useResumeChainMutation } from "../hooks/magicHooks.generated.js";
import { useOutputIsFromPendingMagicEvent } from "../hooks/magicHooks.js";
import { useDispatch, useStore } from "../redux/hooks.js";
import { appSessionMPSelectors } from "../redux/slices/appSessionMPSlice.js";
import {
  STILL_THINKING_THRESHOLD,
  magicActions,
  magicSelectors,
} from "../redux/slices/logicViewSlice.js";
import { getModel } from "../state/models/useModel";
import { useHexVersionAOContext } from "../util/hexVersionAOContext.js";
import { useSessionContext } from "../util/sessionContext.js";
import { useHexFlag } from "../util/useHexFlags";

import {
  GetStreamingEditDocument,
  GetStreamingEditMutation,
  GetStreamingEditMutationVariables,
  MagicStreamingResponseDocument,
  MagicStreamingResponseSubscription,
  MagicStreamingResponseSubscriptionVariables,
  useCancelMagicRequestMutation,
} from "./useCreateSmartEdit.generated";

gql`
  mutation getStreamingEdit(
    $cellId: CellId!
    $kind: SmartEditType!
    $input: String!
    $customInstruction: String
    $traceback: String
    $autoTriggered: Boolean!
    $eventId: MagicEventId!
    $mentionedTableIds: [DataSourceTableId!]!
    $richTextPrompt: RichTextDocument
    $mentionedDataframes: [MentionedDataframeName!]!
  ) {
    getStreamingEditV2(
      cellId: $cellId
      kind: $kind
      customInstruction: $customInstruction
      traceback: $traceback
      input: $input
      autoTriggered: $autoTriggered
      eventId: $eventId
      mentionedTableIds: $mentionedTableIds
      mentionedDataframes: $mentionedDataframes
      richTextPrompt: $richTextPrompt
    ) {
      magicEvent {
        id
        eventSource
      }
      user {
        id
        overMagicUsageLimit
      }
    }
  }
`;

gql`
  subscription MagicStreamingResponse($magicEventId: MagicEventId!) {
    magicStreamingResponse(magicEventId: $magicEventId) {
      token
      done
      error
      reset
      accumulatedResponse
      magicEventId
    }
  }
`;

gql`
  mutation cancelMagicRequest($id: MagicEventId!) {
    cancelMagicRequest(id: $id)
  }
`;

gql`
  mutation AcceptOrRejectStreamedMagicEvent(
    $id: MagicEventId!
    $userFacingResult: String!
    $acceptType: MagicAcceptType!
  ) {
    acceptOrRejectStreamedMagicEvent(
      id: $id
      userFacingResult: $userFacingResult
      acceptType: $acceptType
    )
  }
`;

export interface CreateSmartEditArgs {
  traceback?: string;
  kind: MagicKeyword;
  customInstruction?: string;
  richTextPrompt?: RichTextDocument;
  setLoaded?: boolean;
  hidePopover?: boolean;
  mentionedTableIds?: DataSourceTableId[];
  mentionedDataframes?: MentionedDataframeName[];
}

export interface ResumeSmartEditArgs {
  magicEvent: MagicEventFragment;
  smartEditModel: Editor.ITextModel;
  model: Editor.ITextModel;
  forMagicAnalysis?: boolean;
}

function overwriteFullModel({
  model,
  text,
}: {
  model: Editor.ITextModel;
  text: string;
}): void {
  const fullRange = model.getFullModelRange();
  const selection = new Selection(
    fullRange.startLineNumber,
    fullRange.startColumn,
    fullRange.endLineNumber,
    fullRange.endColumn,
  );
  model.pushStackElement();
  model.pushEditOperations(
    [selection],
    [
      {
        range: selection,
        text,
      },
    ],
    () => [],
  );
  model.pushStackElement();
}

export function useCreateSmartEdit({
  cellId,
  magicEventId,
}: {
  cellId?: CellId;
  magicEventId?: MagicEventId;
}): {
  /**
   * AirlockEventId is the optional id of the airlock this edit happens in
   */
  acceptSmartEdit: (
    acceptType: MagicAcceptType,
    airlockEventId?: MagicEventId,
  ) => void;
  createSmartEdit: (args: CreateSmartEditArgs) => void;
  rejectSmartEdit: () => void;
  cancelEdit: () => void;
  resumeSmartEdit: (args: ResumeSmartEditArgs) => void;
} {
  const getCellContents = useCellContentsGetter();
  const client = useApolloClient();
  const [cancelMagicRequest] = useCancelMagicRequestMutation();
  const subscriptionRef = useRef<Subscription | undefined>(undefined);
  const insertCell = useInsertCell();
  const [stillThinkingTimeoutId, setStillThinkingTimeoutId] = useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const { appSessionId } = useSessionContext();

  const dispatch = useDispatch();
  const { dispatchAO } = useHexVersionAOContext();
  const { formatCellById } = useFormatCells();
  const [resumeChain] = useResumeChainMutation();
  const getMagicEvents = useMagicEventsGetter();
  const store = useStore();
  const getLatestMagicEventForCell = useLatestMagicEventForCellGetter();
  const magicRunEdits = useHexFlag("magic-run-edits");
  const outputFromMagic = useOutputIsFromPendingMagicEvent(cellId);

  const finalizeSmartEdit = useCallback(
    (setLoaded = true) => {
      if (!cellId) throw new Error("missing cellId");
      if (stillThinkingTimeoutId) {
        clearTimeout(stillThinkingTimeoutId);
      }
      dispatch(
        magicActions.setStillThinking({
          cellId,
          data: false,
        }),
      );
      dispatch(
        magicActions.setSmartEditLoading({
          cellId,
          data: false,
        }),
      );
      if (setLoaded) {
        dispatch(
          magicActions.setSmartEditSuccessfullyLoaded({
            cellId,
          }),
        );
      }

      // Clear the loading state on the model
      const smartEditModel = getImperativeModel(cellId);
      if (!smartEditModel) throw new Error("missing models to accept");
    },
    [cellId, dispatch, stillThinkingTimeoutId],
  );

  const cancelEdit = useCallback(async () => {
    if (magicEventId) {
      subscriptionRef.current?.unsubscribe();
      finalizeSmartEdit();
      await cancelMagicRequest({
        variables: { id: magicEventId },
      });
    }
  }, [cancelMagicRequest, finalizeSmartEdit, magicEventId]);

  const handleSubscribe = useCallback(
    ({
      baseText,
      forMagicAnalysis,
      model,
      onDone,
    }: {
      model: Editor.ITextModel;
      onDone?: () => void;
      baseText: string;
      forMagicAnalysis: boolean;
    }) => {
      if (!cellId) throw new Error("missing cellId");
      if (!magicEventId) throw new Error("missing magicEventId");
      subscriptionRef.current?.unsubscribe();
      subscriptionRef.current = client
        .subscribe<
          MagicStreamingResponseSubscription,
          MagicStreamingResponseSubscriptionVariables
        >({
          query: MagicStreamingResponseDocument,
          fetchPolicy: "network-only",
          variables: { magicEventId },
        })
        .subscribe({
          next: (data) => {
            const magicEvent = getMagicEvents()[magicEventId];
            // On every token received, check if the magic event has been cancelled and unsubscribe if so
            if (magicEvent?.status === MagicEventStatus.CANCELLED) {
              subscriptionRef.current?.unsubscribe();

              // for magic analysis we just cancel and attempt to format the
              // cell as is since we stream directly into it
              if (forMagicAnalysis) {
                formatCellById({
                  cellId,
                  sourceOverride: model.getValue(),
                  suppressErrorToast: true,
                });
              } else {
                // for single cells we want to clear the smartEdit model
                model.setValue("");
              }
              return;
            }
            if (magicEvent?.status === MagicEventStatus.REVIEWED) {
              return;
            }
            const accumulatedResponse =
              data.data?.magicStreamingResponse.accumulatedResponse;
            const token = data.data?.magicStreamingResponse.token;
            const errorMsg = data.data?.magicStreamingResponse.error;
            // If we get a reset message, it means we're trying again with the
            // fallback model, so clear any tokens we've already streamed
            if (data.data?.magicStreamingResponse.reset) {
              model.setValue(baseText);
            }
            if (errorMsg) {
              subscriptionRef.current?.unsubscribe();
              if (stillThinkingTimeoutId) {
                clearTimeout(stillThinkingTimeoutId);
              }
              dispatch(
                magicActions.setStillThinking({
                  cellId,
                  data: false,
                }),
              );
              dispatch(
                magicActions.setSmartEditLoading({
                  cellId,
                  data: false,
                }),
              );
            } else if (accumulatedResponse && token) {
              const line = model.getLineCount();
              const column = model.getLineLength(line);
              if (model.getValue() === "" || forMagicAnalysis) {
                overwriteFullModel({
                  model,
                  text: baseText + accumulatedResponse,
                });
              } else {
                model.pushEditOperations(
                  [],
                  [
                    {
                      range: new Range(
                        line + 1,
                        column + 1,
                        line + 1,
                        column + 1,
                      ),
                      text: token,
                    },
                  ],
                  () => null,
                );
              }
            } else if (data.data?.magicStreamingResponse.done) {
              if (accumulatedResponse != null) {
                overwriteFullModel({
                  model,
                  // For MA, the accumulatedResponse includes the full cell source,
                  // so we don't want to prepend the baseText
                  text:
                    (forMagicAnalysis ? "" : baseText) + accumulatedResponse,
                });
              }
              subscriptionRef.current?.unsubscribe();
              finalizeSmartEdit(!forMagicAnalysis);
              onDone?.();
            }
          },
        });
    },
    [
      cellId,
      client,
      dispatch,
      finalizeSmartEdit,
      formatCellById,
      getMagicEvents,
      magicEventId,
      stillThinkingTimeoutId,
    ],
  );

  /**
   * Updates single cell magic to handle the acceptance UX for accepting the
   * edit and conditionally updates the event status, since it may have already
   * been updated from a different context like the prompt bar.
   *
   * This should only be called within a cell.
   */
  const { runCell } = useRunCell();
  const acceptSmartEdit = useCallback(
    async (
      acceptType: MagicAcceptType,
      airlockEventId?: MagicEventId, // used to resume the chain
    ): Promise<void> => {
      if (!cellId) throw new Error("missing cellId");
      if (!magicEventId) throw new Error("missing magicEventId");

      const state = store.getState();

      const appSessionCellId = appSessionMPSelectors
        .getAppSessionCellSelectors(appSessionId)
        .selectByCellId(state, cellId)?.id;

      const model = getModel(cellId);
      const smartEditModel = getImperativeModel(cellId);
      if (!model || !smartEditModel)
        throw new Error("missing models to accept");

      const magicEvent = getMagicEvents()[magicEventId];
      const parentEventId = magicEvent?.parentEventId;
      const addToNewCell = acceptType === MagicAcceptType.KEEP_IN_NEW_CELL;
      const shouldRun = acceptType === MagicAcceptType.KEEP_AND_RUN;

      const userFacingResult = smartEditModel.getValue();
      const eventsToUpdate = [parentEventId, magicEventId].filter(notEmpty);
      if (eventsToUpdate.length > 0) {
        dispatchAO(
          eventsToUpdate
            .map((evtId) => [
              UPDATE_MAGIC_EVENT.create(evtId, "accepted", true),
              UPDATE_MAGIC_EVENT.create(
                evtId,
                "userFacingResult",
                userFacingResult,
              ),
              UPDATE_MAGIC_EVENT.create(evtId, "acceptType", acceptType),
              UPDATE_MAGIC_EVENT.create(
                evtId,
                "status",
                MagicEventStatus.REVIEWED,
              ),
            ])
            .flat(),
        );
      }
      dispatch(
        magicActions.clearSmartEdit({
          cellId,
          data: {
            clearPrompt: true,
          },
        }),
      );
      dispatch(
        magicActions.closeEditBar({
          cellId,
        }),
      );
      if (addToNewCell) {
        const contents = getCellContents(cellId);
        insertCell(
          duplicateCellContentsPayload(contents, {
            source: userFacingResult,
          }),
          {
            location: {
              type: "relative",
              position: "after",
              targetCellId: cellId,
            },
            runAfterInsertion: shouldRun ?? false,
          },
        );
      } else {
        overwriteFullModel({ model, text: userFacingResult });
        if (shouldRun) {
          if (airlockEventId != null) {
            void resumeChain({
              variables: {
                id: airlockEventId,
                startingCellId: cellId,
                startingCellContents: userFacingResult,
              },
            });
          } else {
            void runCell({
              cellId,
              appSessionCellId,
              forLogicView: true,
            });
          }
        }
      }
      smartEditModel.setValue("");

      // if the current draft prompt is the same as the one we're accepting, clear it.
      const airlockState = magicSelectors.selectDraftAirlockState(state);
      const currentPrompt = convertRichTextToPlainText(
        airlockState.promptV2 ?? [],
      );
      const eventPrompt = convertRichTextToPlainText(
        magicEvent?.richTextPrompt ?? [],
      );
      if (currentPrompt === eventPrompt) {
        dispatch(magicActions.setDraftAirlockPrompt(""));
        dispatch(magicActions.setDraftAirlockPromptV2(undefined));
      }
    },
    [
      cellId,
      magicEventId,
      store,
      appSessionId,
      getMagicEvents,
      dispatch,
      dispatchAO,
      getCellContents,
      insertCell,
      resumeChain,
      runCell,
    ],
  );

  /**
   * Updates single cell magic to handle the rejection UX for rejecting the edit
   * and conditionally updates the event status, since it may have already been
   * updated from a different context like the prompt bar.
   *
   * This should only be called within a cell.
   */
  const rejectSmartEdit = useCallback(() => {
    if (!cellId) throw new Error("missing cellId");
    const smartEditModel = getImperativeModel(cellId);
    if (!smartEditModel) throw new Error("missing model to reject");

    const state = store.getState();

    const appSessionCellId = appSessionMPSelectors
      .getAppSessionCellSelectors(appSessionId)
      .selectByCellId(state, cellId)?.id;

    const userFacingResult = smartEditModel.getValue();
    if (magicEventId) {
      const parentEventId = getMagicEvents()[magicEventId]?.parentEventId;
      const eventsToUpdate = [parentEventId, magicEventId].filter(notEmpty);
      dispatch(
        magicActions.closeEditBar({
          cellId,
        }),
      );

      dispatchAO(
        eventsToUpdate
          .map((evtId) => [
            UPDATE_MAGIC_EVENT.create(evtId, "accepted", false),
            UPDATE_MAGIC_EVENT.create(
              evtId,
              "userFacingResult",
              userFacingResult,
            ),
            UPDATE_MAGIC_EVENT.create(
              evtId,
              "acceptType",
              MagicAcceptType.REJECT,
            ),
            UPDATE_MAGIC_EVENT.create(
              evtId,
              "status",
              MagicEventStatus.REVIEWED,
            ),
          ])
          .flat(),
      );
    }
    smartEditModel.setValue("");
    finalizeSmartEdit(false);
    if (magicRunEdits && outputFromMagic) {
      void runCell({
        cellId,
        forLogicView: true,
        appSessionCellId,
      });
    }
  }, [
    cellId,
    store,
    appSessionId,
    magicEventId,
    finalizeSmartEdit,
    magicRunEdits,
    outputFromMagic,
    getMagicEvents,
    dispatch,
    dispatchAO,
    runCell,
  ]);

  const resumeSmartEdit = useCallback(
    ({
      forMagicAnalysis = false, // This essentially means we should stream directly into the cell instead of the transient diff editor
      model,
      smartEditModel,
    }: ResumeSmartEditArgs): void => {
      if (!cellId) throw new Error("missing cellId");
      if (!magicEventId) throw new Error("missing magicEventId");
      const magicEvent = getMagicEvents()[magicEventId];
      if (magicEvent == null) {
        throw new Error("missing magicEvent");
      }
      // For magic analysis we rely on cell source to correctly have the latest
      // contents and won't write to the model
      // For single cell we want to make sure we update the diff view
      const writeToModel = forMagicAnalysis ? model : smartEditModel;
      if (!forMagicAnalysis && magicEvent.result != null) {
        writeToModel.setValue(magicEvent.result);
        return;
      }

      if (!forMagicAnalysis) {
        if (magicEvent.failureReason != null) {
          dispatch(
            magicActions.setSmartEditErrored({
              cellId,
            }),
          );
        }
        dispatch(
          magicActions.setSmartEditType({
            cellId,
            data: MagicKeywordLiteral.check(magicEvent.eventType),
          }),
        );
        dispatch(
          magicActions.setSideBySideDiff({
            cellId,
            data: magicEvent.eventType !== SmartEditType.INSERT,
          }),
        );
        dispatch(
          magicActions.setMagicPrompt({
            cellId,
            data: magicEvent.richTextPrompt ?? undefined,
          }),
        );
      }
      const source = model.getValue();
      const baseText =
        magicEvent.eventType === MagicKeyword.INSERT
          ? source + (source.length > 0 ? "\n" : "")
          : magicEvent.eventType === MagicKeyword.COMPLETION
            ? source
            : "";
      dispatch(
        magicActions.setMagicEventId({
          cellId,
          data: magicEvent.id,
        }),
      );

      if (
        magicEvent.status === MagicEventStatus.PENDING_REVIEW &&
        // not needed for magic analysis because we do a full cell update on the backend as well
        !forMagicAnalysis
      ) {
        const updatedText = baseText + (magicEvent.result ?? "");
        // if we'd be overwriting with the exact same content, don't actually update to avoid
        // the text flashing
        if (writeToModel.getValue().trim() === updatedText.trim()) {
          return;
        }
        overwriteFullModel({
          model: writeToModel,
          text: updatedText,
        });
        finalizeSmartEdit(!forMagicAnalysis);
      } else if (magicEvent.status === MagicEventStatus.LOADING) {
        if (!forMagicAnalysis) {
          dispatch(
            magicActions.setSmartEditLoading({
              cellId,
              data: true,
            }),
          );
          setStillThinkingTimeoutId(
            setTimeout(() => {
              dispatch(
                magicActions.setStillThinking({
                  cellId,
                  data: true,
                }),
              );
            }, STILL_THINKING_THRESHOLD),
          );
        }
        handleSubscribe({
          model: writeToModel,
          baseText,
          forMagicAnalysis,
          onDone: undefined,
        });
      }
    },
    [
      cellId,
      dispatch,
      finalizeSmartEdit,
      getMagicEvents,
      handleSubscribe,
      magicEventId,
    ],
  );

  /**
   * Triggers creating a single cell edit. This can be triggered from both within
   * a cell or from a different context like a prompt bar.
   */
  const createSmartEdit = useCallback(
    ({
      customInstruction,
      kind,
      mentionedDataframes,
      mentionedTableIds,
      richTextPrompt,
      traceback,
    }: CreateSmartEditArgs): void => {
      if (!cellId) throw new Error("missing cellId");
      const contents = getCellContents(cellId);
      if (
        contents.__typename !== "CodeCell" &&
        contents.__typename !== "SqlCell" &&
        contents.__typename !== "MarkdownCell"
      ) {
        return;
      }
      dispatch(
        magicActions.setSmartEditType({
          cellId,
          data: kind,
        }),
      );
      dispatch(
        magicActions.setSmartEditLoading({
          cellId,
          data: true,
        }),
      );
      dispatch(
        magicActions.setSmartEditType({
          cellId,
          data: kind,
        }),
      );
      dispatch(
        magicActions.setSideBySideDiff({
          cellId,
          data: kind !== SmartEditType.INSERT,
        }),
      );
      setStillThinkingTimeoutId(
        setTimeout(() => {
          dispatch(
            magicActions.setStillThinking({
              cellId,
              data: true,
            }),
          );
        }, STILL_THINKING_THRESHOLD),
      );

      // TODO(MAGIC): instead of reading cell source here, we might want to have it passed in
      // so if the model has changes that haven't been debounced yet we still have the latest source
      let { source } = contents;

      // We want to use the result of the most recent edit as the basis for our next edit
      // In theory, we accept the edit before creating the next one, and this shouldn't be needed, but in practice,
      // it takes a few ticks for cell's source to actually get updated, so here we just read directly off the magic
      // event to ensure this works as intended.
      const lastEvent = getLatestMagicEventForCell(cellId);

      if (
        lastEvent &&
        (lastEvent.status === MagicEventStatus.PENDING_REVIEW ||
          lastEvent.acceptType === MagicAcceptType.KEEP_AND_EDIT)
      ) {
        source = lastEvent.result ?? source;
      }

      dispatch(
        magicActions.setSmartEditLoading({
          cellId,
          data: true,
        }),
      );
      const eventId = uuid() as MagicEventId;
      dispatch(
        magicActions.setMagicEventId({
          cellId,
          data: eventId,
        }),
      );

      client
        .mutate<GetStreamingEditMutation, GetStreamingEditMutationVariables>({
          mutation: GetStreamingEditDocument,
          variables: {
            eventId,
            customInstruction: customInstruction ?? null,
            richTextPrompt: richTextPrompt ?? null,
            kind: MagicCellEditTypeLiteral.check(kind),
            traceback: traceback ?? null,
            input: source,
            cellId,
            autoTriggered: false,
            mentionedTableIds: mentionedTableIds ?? [],
            mentionedDataframes: mentionedDataframes ?? [],
          },
        })
        .catch((_e: ApolloError) => {
          subscriptionRef.current?.unsubscribe();
          clearTimeout(stillThinkingTimeoutId);
          dispatch(
            magicActions.setStillThinking({
              cellId,
              data: false,
            }),
          );
          dispatch(
            magicActions.setSmartEditErrored({
              cellId,
            }),
          );
        });
    },
    [
      cellId,
      getCellContents,
      dispatch,
      client,
      getLatestMagicEventForCell,
      stillThinkingTimeoutId,
    ],
  );

  return {
    acceptSmartEdit,
    createSmartEdit,
    rejectSmartEdit,
    cancelEdit,
    resumeSmartEdit,
  };
}
